<template>
<div class="row tgju-widgets-row dashboard-widgets-technical-list dash-full-height">
    <div class="col-12">
        <div class="tgju-widget light">
            <div class="list-widget widget-head-page list-row bold list-row-title">
                <div class="list-col l-title head-color">
                    <span class="with-icon"><i class="uil uil-chart"></i>{{ data[this.$route.params.name].title }}</span>
                </div>
                <div class="list-col l-item pointer hover head-color">
                    <span>
                        <div class="fa fa-question-circle l-item-btn-pages"></div> راهنما
                    </span>
                </div>
            </div>
            <div class="tgju-widget-content full-padding overflow-hidden">
                <MarketStatic :data="data[this.$route.params.name].items"></MarketStatic>
            </div>
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
form i {
    margin-left: 5px;
}
</style>

<script>
// این ویو برای ماژول بازار بخش استاتیک مورد استفاده قرار میگیرد
import MarketStaticValues from "@/assets/static_market_page_value";
import MarketStatic from "@/components/Market/Static";

export default {
    name: "marketStaticPage",
    components: {
        MarketStatic,
    },
    data: function () {
        return {
            data: MarketStaticValues,
        };
    },
    mounted() {},
};
</script>
